import { withModalWindow } from 'bv-hocs';
import { t } from 'bv-i18n';
import SearchApp from './components/app';
import reducer from './duck';

const { addReducers } = window.reduxState;

addReducers({
  search: reducer,
});

export default withModalWindow(
  t('javascript.search.modal_title'),
  { className: 'search-modal' },
)(SearchApp);
