import { useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Spinner } from 'bv-components';
import { useFetch } from 'bv-hooks';
import { modalBack } from 'bv-helpers/location';
import { fetchSearchConfig } from '../api';
import useSearchParams from '../hooks/use_search_params';
import { CasinoSearchResults } from '../lazy_components';
import SearchInput from './search_input';
import DefaultSearchResults from './search_results';
import SearchTypeTabs from './search_type_tabs';

const App = () => {
  const history = useHistory();
  const [searchConfig, loading] = useFetch(fetchSearchConfig);
  const { pathname, search } = useLocation();
  const { searchType } = useSearchParams();

  const setSearchType = useCallback((type) => {
    const historyAction = searchType ? 'push' : 'replace';
    const searchParams = new URLSearchParams(search);
    searchParams.set('type', type);
    searchParams.delete('term');
    history[historyAction]({ pathname, search: searchParams.toString() });
  }, [search]);

  useEffect(() => {
    if (searchConfig) {
      if (!searchConfig.searchTypes.length) {
        modalBack();
      } else if (!(searchType && searchConfig.searchTypes.includes(searchType))) {
        setSearchType(searchConfig.searchTypes[0]);
      }
    }
  }, [searchConfig]);

  if (loading || !searchType) return <Spinner />;

  const SearchResults = searchType === 'CASINO' ? CasinoSearchResults : DefaultSearchResults;

  return (
    <div className="search-wrapper">
      {
        searchConfig.searchTypes.length > 1 && (
          <SearchTypeTabs
            activeTab={searchType}
            onTabClick={setSearchType}
            tabs={searchConfig.searchTypes}
          />
        )
      }
      <SearchInput />
      <SearchResults />
    </div>
  );
};

export default App;
