import PropTypes from 'prop-types';
import { ButtonTab } from 'bv-components';

const SearchTypeTab = ({ active, onClick, title }) => (
  <ButtonTab
    type="sportbook-section-tabs"
    onClick={onClick}
    active={active}
  >
    {title}
  </ButtonTab>
);

SearchTypeTab.propTypes = {
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SearchTypeTab;
