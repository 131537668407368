import { useState, useEffect, useCallback } from 'react';
import { t } from 'bv-i18n';
import { getRecentSearches, removeRecentSearch } from 'bv-helpers/recent_search_results_cookie';
import useSlicedSearchParams from '../hooks/use_sliced_search_params';
import RecentSearch from './recent_search';

const RecentSearches = () => {
  const { searchType } = useSlicedSearchParams();
  const [recentSearches, setRecentSearches] = useState(getRecentSearches(searchType));

  const removeTerm = useCallback((term) => {
    removeRecentSearch(searchType, term);
    setRecentSearches(getRecentSearches(searchType));
  }, [searchType]);

  useEffect(() => setRecentSearches(getRecentSearches(searchType)), [searchType]);

  return (
    <div className="search-recent-wrapper">
      {
        recentSearches.length > 0 ? (
          <>
            <p>{t('javascript.search.recent')}</p>

            <div className="search-recent">
              {
                recentSearches.map((term) => (
                  <RecentSearch key={term} term={term} onRemoveClick={removeTerm} />
                ))
              }
            </div>
          </>
        ) : (
          <p>{t(`javascript.search.${searchType.toLowerCase()}.no_recent_searches`)}</p>
        )
      }

    </div>
  );
};

export default RecentSearches;
