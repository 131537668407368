import PropTypes from 'prop-types';
import { Carousel } from 'bv-components';
import SearchResultsTab from './search_results_tab';

const SearchResultsTabs = ({ activeTab, onTabClick, tabs }) => (
  <Carousel
    scrollStep={120}
    className="search-results-tabs"
    embedded
  >
    {tabs.map((title, i) => (
      <SearchResultsTab
        title={title}
        active={activeTab === i}
        key={title}
        onClick={() => onTabClick(i)}
      />
    ))}
  </Carousel>
);

SearchResultsTabs.propTypes = {
  activeTab: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SearchResultsTabs;
