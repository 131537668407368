import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'underscore';
import { dataLayer } from 'bv-helpers';
import omitSearch from '../helpers/omit_search';
import useSlicedSearchParams from './use_sliced_search_params';

export default (search, clearSearch) => {
  const { searchTerm, searchType } = useSlicedSearchParams();
  const [showRecentSearches, setShowRecentSearches] = useState(!searchTerm);

  const throttleSearch = useCallback(debounce((term, type) => {
    setShowRecentSearches(false);
    search(term, type);
    if (type !== 'CASINO') {
      dataLayer.push({
        event: 'Site Search',
        Query: term,
        Search_Product: type,
      });
    }
  }, 500), [search]);

  useEffect(() => {
    if (searchTerm && searchType && !omitSearch(searchType, searchTerm)) {
      throttleSearch(searchTerm, searchType);
    } else {
      setShowRecentSearches(true);
      throttleSearch.cancel();
    }
  }, [searchTerm, searchType, throttleSearch]);

  useEffect(() => () => {
    clearSearch();
    throttleSearch.cancel();
  }, []);

  return { showRecentSearches };
};
