import PropTypes from 'prop-types';
import { Button } from 'bv-components';

const SearchResultsTab = ({ active, onClick, title }) => (
  <Button
    onClick={onClick}
    noClass
    active={active}
    className="bvs-button-chip is-small"
  >
    {title}
  </Button>
);

SearchResultsTab.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default SearchResultsTab;
