import useSearchParams from './use_search_params';

const MAX_QUERY_LENGTH = 100;

const useSlicedSearchParams = () => {
  const { searchTerm, searchType } = useSearchParams();

  return {
    searchTerm: searchTerm.slice(0, MAX_QUERY_LENGTH),
    maxTermLength: MAX_QUERY_LENGTH,
    searchType,
  };
};

export default useSlicedSearchParams;
