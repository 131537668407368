import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import SearchTypeTab from './search_type_tab';

const SearchTypeTabs = ({ activeTab, onTabClick, tabs }) => (
  <div className="search__tabs">
    {tabs.map((type) => (
      <SearchTypeTab
        title={t(`javascript.search.${type.toLowerCase()}.tab_title`)}
        active={activeTab === type}
        key={type}
        onClick={() => onTabClick(type)}
      />
    ))}
  </div>
);

SearchTypeTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SearchTypeTabs;
