import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import useSlicedSearchParams from '../hooks/use_sliced_search_params';

const NoSearchResults = ({ className }) => {
  const { searchType } = useSlicedSearchParams();
  return (
    <div className={`search-noresult ${className}`}>
      <div className="search-noresult__title">{t(`javascript.search.${searchType.toLowerCase()}.no_results_title`)}</div>
      <div className="search-noresult__icon" />
      <p>{t(`javascript.search.${searchType.toLowerCase()}.no_results_message`)}</p>
    </div>
  );
};

NoSearchResults.propTypes = {
  className: PropTypes.string,
};

NoSearchResults.defaultProps = {
  className: '',
};

export default NoSearchResults;
