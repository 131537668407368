import PropTypes from 'prop-types';
import { format } from 'bv-helpers/datetime';

const START_TIME = '%%startTime%%';

const formatSubtitle = (text, { startTime }) => (text.includes(START_TIME)
  ? text.replace(START_TIME, format(startTime)) : text);

const SearchResultsListItemSubtitle = ({ data, text }) => (
  <p>{formatSubtitle(text, data)}</p>
);

SearchResultsListItemSubtitle.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  text: PropTypes.string.isRequired,
};

export default SearchResultsListItemSubtitle;
