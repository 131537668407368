import { useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';
import useSlicedSearchParams from '../hooks/use_sliced_search_params';

const SearchInput = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { searchTerm, searchType, maxTermLength } = useSlicedSearchParams();

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef) {
      inputRef.current.focus();
    }
  }, [inputRef, searchType]);

  const pushHistory = (searchParams) => {
    history.push({ pathname, search: searchParams.toString() });
  };

  const clearSearch = () => {
    const searchParams = new URLSearchParams(search);
    searchParams.delete('term');
    pushHistory(searchParams);
    inputRef.current.focus();
  };

  const onChange = (event) => {
    const { value } = event.target;
    const searchParams = new URLSearchParams(search);
    if (!value) {
      searchParams.delete('term');
    } else {
      searchParams.set('term', value);
    }
    pushHistory(searchParams);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    inputRef.current.blur();
  };

  return (
    <form className="search-form" onSubmit={onSubmit}>
      <Icon
        id="search"
        className="search-form__icon"
        main
      />
      <input
        className="search-form__input"
        type="text"
        onChange={onChange}
        placeholder={t(`javascript.search.${searchType.toLowerCase()}.input_placeholder`)}
        value={searchTerm}
        ref={inputRef}
        maxLength={maxTermLength}
      />
      {
        searchTerm && (
          <Icon
            id="close"
            className="search-form__close"
            type="search-input-close-button"
            onClick={clearSearch}
          />
        )
      }
    </form>
  );
};

export default SearchInput;
