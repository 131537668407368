import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export const fetchSearchConfig = () => (
  getJSON(`/api/${locale()}/settings/search`)
);

export const fetchSearchResult = (keyword, type) => (
  getJSON(`/api/${locale()}/search/results`, {
    keyword,
    search_type: type,
  })
);
