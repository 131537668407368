import PropTypes from 'prop-types';
import { ButtonCard, Icon } from 'bv-components';
import { useLocation } from 'react-router-dom';

const RecentSearch = ({ onRemoveClick, term }) => {
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  searchParams.set('term', term);
  const href = `${pathname}?${searchParams.toString()}`;

  const removeTerm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemoveClick(term);
  };

  return (
    <ButtonCard
      to={href}
      className="search-recent__item"
      dataReact
    >
      {term}
      <Icon
        id="close"
        className="search-recent__item-close"
        active
        onClick={removeTerm}
      />
    </ButtonCard>
  );
};

RecentSearch.propTypes = {
  onRemoveClick: PropTypes.func.isRequired,
  term: PropTypes.string.isRequired,
};

export default RecentSearch;
