import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'bv-components';
import { locale } from 'bv-i18n';
import { addRecentSearch } from 'bv-helpers/recent_search_results_cookie';
import { dataLayer } from 'bv-helpers';
import useSlicedSearchParams from '../hooks/use_sliced_search_params';
import Subtitle from './search_results_list_item_subtitle';

const SearchResultsListItem = ({
  data, href, index, resultCount, subtitle, tabTitle, title,
}) => {
  const { searchTerm, searchType } = useSlicedSearchParams();

  const onClick = useCallback(() => {
    addRecentSearch(searchType, searchTerm);
    dataLayer.push({
      event: 'Search Result Click',
      Query: searchTerm,
      Search_Product: searchType,
      Active_Tab: tabTitle,
      Result_Count: resultCount,
      Click_Index: index,
      Result_URL: `/${locale()}${href}`,
    });
  }, [searchTerm, searchType]);

  return (
    <div className="bvs-card bvs-link search-results__item" onClick={onClick}>
      <Link to={href} data-react="true">
        <div className="search-results__item-text">
          <p>{title}</p>
          {subtitle && <Subtitle text={subtitle} data={data} />}
        </div>
        <span className="bvs-icon is-arrow-right" />
      </Link>
    </div>
  );
};

SearchResultsListItem.propTypes = {
  data: PropTypes.instanceOf(Object),
  href: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  resultCount: PropTypes.number.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  tabTitle: PropTypes.string.isRequired,
};

SearchResultsListItem.defaultProps = {
  data: {},
  subtitle: '',
};

export default SearchResultsListItem;
