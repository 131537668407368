import { useLocation } from 'react-router';

export default () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return {
    searchTerm: params.get('term') || '',
    searchType: params.get('type') || '',
  };
};
