import PropTypes from 'prop-types';
import SearchResultsListItem from './search_results_list_item';

const SearchResultsList = ({ results, tabTitle }) => (
  <div className="search-results">
    {
      results.map((result, i) => (
        <SearchResultsListItem
          {...result}
          key={result.href}
          index={i}
          resultCount={results.length}
          tabTitle={tabTitle}
        />
      ))
    }
  </div>
);

SearchResultsList.propTypes = {
  results: PropTypes.arrayOf(Object).isRequired,
  tabTitle: PropTypes.string.isRequired,
};

export default SearchResultsList;
