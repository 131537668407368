import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { compose } from 'underscore';
import { connect } from 'react-redux';
import { dataLayer } from 'bv-helpers';
import { clearSearch as clearSearchAction, search as searchAction } from '../duck';
import useSlicedSearchParams from '../hooks/use_sliced_search_params';
import useThrottleSearch from '../hooks/use_throttle_search';
import NoSearchResults from './no_search_results';
import SearchResultsTabs from './search_results_tabs';
import SearchResultsList from './search_results_list';
import RecentSearches from './recent_searches';

const SearchResults = ({
  fetching, results, search, clearSearch,
}) => {
  const { searchTerm, searchType } = useSlicedSearchParams();
  const [activeTab, setActiveTab] = useState(0);
  const { showRecentSearches } = useThrottleSearch(search, clearSearch);

  const pushDataLayerEvent = (event, index) => {
    dataLayer.push({
      event,
      Query: searchTerm,
      Search_Product: searchType,
      Active_Tab: results?.[index]?.tabTitle || null,
      Result_Count: results?.[index]?.results?.length || 0,
    });
  };

  const onTabClick = (index) => {
    setActiveTab(index);
    pushDataLayerEvent('Search Tab Change', index);
  };

  useEffect(() => {
    if (fetching) {
      setActiveTab(0);
    } else {
      pushDataLayerEvent('Search Results Shown', 0);
    }
  }, [fetching, results]);

  if (showRecentSearches) return <RecentSearches />;

  if (fetching) return <Spinner />;

  const tabs = results.map((result) => result.tabTitle);

  return (
    <div className="search-results-wrapper">
      {results.length > 0 ? (
        <>
          <SearchResultsTabs activeTab={activeTab} tabs={tabs} onTabClick={onTabClick} />
          <SearchResultsList {...results[activeTab]} />
        </>
      ) : <NoSearchResults />}
    </div>
  );
};

SearchResults.propTypes = {
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(Object).isRequired,
  fetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  ...state.search,
});

const mapDispatchToProps = (dispatch) => ({
  search: compose(dispatch, searchAction),
  clearSearch: compose(dispatch, clearSearchAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
