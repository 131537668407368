import { fetchSearchResult } from './api';

const SEARCH_INIT = 'search/SEARCH_INIT';
const SET_SEARCH_RESULT = 'search/SET_SEARCH_RESULT';
const CLEAR_SEARCH = 'search/CLEAR_SEARCH';

export const searchInit = (term) => ({
  type: SEARCH_INIT,
  term,
});

export const setSearchResult = (payload) => ({
  type: SET_SEARCH_RESULT,
  payload,
});

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
});

let currentSearchTerm;

export const search = (term, type) => (dispatch) => {
  currentSearchTerm = term;

  dispatch(searchInit(term));

  return fetchSearchResult(term, type)
    .then((response) => {
      if (currentSearchTerm !== term) return;

      dispatch(setSearchResult(response));
    });
};

export const initialState = {
  results: [],
  tabbed: false,
  type: '',
  fetching: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SEARCH_INIT:
      return {
        results: [],
        fetching: true,
      };
    case SET_SEARCH_RESULT:
    {
      return {
        ...state,
        ...action.payload,
        fetching: false,
      };
    }
    case CLEAR_SEARCH:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
